<template>
  <div>
    <master-component
      :config="config"
      :showRemove="false"
    ></master-component>
  </div>
</template>


<script>
import { getLocations } from "../js/dropdown-service";
import { UserRole, InputType, DialogSize, Usr } from "../js/constants";
import { getToken } from "../js/authorization";

export default {
  data() {
    return {
      config: {},
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "master-mentor",
        shortName: "นักธุรกิจจิตอาสา",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
  },

  computed: {
    urlSection() {
      let searchUrl = "/mentor/search";
      let getUrl = "/mentor";
      let addUrl = "/mentor";
      let editUrl = "/mentor";
      let removeUrl = "/mentor";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: true,
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              columnClass: "col-12 col-md-3",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MENTOR],
        edit: [UserRole.ADMIN, UserRole.MENTOR],
        remove: [],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "name",
            text: "Name",
          },
          {
            name: "email",
            text: "Email",
          },
          {
            name: "tel",
            text: "เบอร์โทร",
          },
          {
            name: "idCardNumber",
            text: "User",
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              text: "Reset password",
              columnClass: "text-right",
              type: InputType.resetPassword,
              extend: {
                url: "/Account/ResetPassword",
              },
              showIf: ({ model }) => {
                let result = false;

                let token = getToken();
                let condition1 = model.id ? true : false;
                let condition2 = token.username == Usr.Support;
                let condition3 = [UserRole.ADMIN, UserRole.MENTOR].includes(
                  token.role
                );

                let others = [condition2, condition3];

                if (condition1 && others.some((x) => x == true)) {
                  result = true;
                }

                return result;
              },
            },
          ],
          [
            {
              name: "name",
              text: "ชื่อ",
              type: InputType.text,
              rule: {
                required: true,
                max: 50,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "email",
              text: "Email",
              type: InputType.text,
              rule: {
                email: true,
                max: 40,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "tel",
              text: "เบอร์โทร",
              type: InputType.text,
              rule: {
                max: 22,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              type: "section",
              text: "Credential",
            },
          ],
          [
            {
              name: "idCardNumber",
              text: "เลขประจำตัวประชาชน",
              type: InputType.text,
              disabledWhenEdit: true,
              rule: {
                required: true,
                idCardNumber: true,
                min: 13,
                max: 13,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "password",
              text: "Password",
              type: InputType.password,
              hideWhenEdit: true,
              rule: {
                required: true,
                regex: "",
                min: 4,
                max: 12,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "confirmPasword",
              text: "Confirm Password",
              type: InputType.password,
              hideWhenEdit: true,
              rule: {
                confirmed: "password",
                required: true,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
  },
};
</script>
